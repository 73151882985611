<template>
  <div>
    <div class="d-flex">
      <h2>Adressverwaltung</h2>
      <!-- <h3>
        {{ tabs }}<br />
        {{ invalidDetailsVisible }}
        <br />/
        {{ selectedAddress }}
      </h3>
      <v-btn @click="invalidDetailsVisible = true">
        showX
      </v-btn> -->
      <!-- <v-spacer> </v-spacer>
      <v-btn rounded class="mr-2" @click="getProjects">
        <v-icon>mdi-sync</v-icon> aktualisieren
      </v-btn>
      <v-badge
        v-if="incompleteProjects.length"
        bordered
        color="error"
        :content="incompleteProjects.length"
        overlap
        style="margin-left: -15px"
      >
        <v-btn
          v-if="incompleteProjects.length"
          rounded
          class="ml-4"
          @click="showIncompleteDialog = true"
        >
          unvollständige Projekte
        </v-btn>
      </v-badge>
      <v-spacer></v-spacer>
      <v-btn rounded class="mr-2" color="primary" @click="getProjectKpis">
        <v-icon>mdi-chart-box-plus-outline</v-icon> Kennzahlen laden
      </v-btn>
      <v-btn
        rounded
        class="mr-2"
        color="primary"
        @click="showAddressSearch = true"
      >
        <v-icon>mdi-home-search-outline</v-icon> Adresse suchen
      </v-btn>
      <v-text-field
        v-model="searchText"
        placeholder="Projekt suchen"
        prepend-inner-icon="mdi-map-search-outline"
        style="width: 100px"
        outlined
        rounded
        dense
        clearable
      >
      </v-text-field> -->
    </div>
    <v-tabs v-model="tabs">
      <v-tab>Adresse suchen</v-tab>
      <v-tab>Neue Adresse</v-tab>
      <v-tab @click="loadAddressesForVerification">
        Addressen in Prüfung
      </v-tab>
      <v-tab
        v-if="userGroups.includes('camunda-admin')"
        @click="loadInvalidAddresses"
      >
        invalide Adressen (varioCRM)
      </v-tab>
      <v-tab>Lauflisten</v-tab>
      <v-tab-item>
        <v-card class="address-input">
          <v-card-title> Suche </v-card-title>
          <v-card-text class="mt-10">
            <AvailabilityForm />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card class="address-input">
          <v-card-title> Adresse anlegen </v-card-title>
          <v-card-text class="mt-2">
            <v-row>
              <v-col :md="convertedPoint || (address && address.city) ? 6 : 10">
                <v-card class="small-card" elevation="1">
                  <v-card-title> Adressinformationen </v-card-title>
                  <v-card-text>
                    <AddressAutocomplete
                      ref="AddressAutocomplete"
                      :disabled="correctMode"
                      :previouslySelectedAddress="address"
                      @update="updateAddress"
                      @updategeo="updateGeo"
                    />
                    <template v-if="districts && districts.length > 1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="error"
                            class="float-left pr-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-alert-circle
                          </v-icon>
                        </template>
                        <span>Bitte richtigen Ortsteil auswählen</span>
                      </v-tooltip>
                    </template>
                    <v-select
                      v-if="districts"
                      v-model="district"
                      label="Ortsteil"
                      :items="districts"
                      :item-text="'addr_gt_ortsteil'"
                      return-object
                      dense
                      class="mt-4"
                      :disabled="correctMode"
                    >
                    </v-select>
                  </v-card-text>
                </v-card>
                <v-card class="small-card" elevation="1">
                  <v-card-title> Gebäudeinformationen </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col md="6">
                        <v-text-field
                          v-model="homes"
                          label="Anzahl Wohneiheiten"
                          persistent-placeholder
                          dense
                          number
                          :disabled="correctMode"
                        />
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="offices"
                          label="Anzahl Gewerbeeinheiten"
                          persistent-placeholder
                          dense
                          number
                          :disabled="correctMode"
                        />
                      </v-col>
                      <v-col md="6">
                        <v-checkbox
                          v-model="publicBuilding"
                          label="öffentliches Gebäude"
                          :disabled="correctMode"
                        ></v-checkbox>
                      </v-col>
                      <v-col md="6">
                        <v-checkbox
                          v-model="wowi"
                          label="Wohnungswirtschaft"
                          :disabled="correctMode"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card class="small-card" elevation="1">
                  <v-card-title> Geoinformationen </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="googleMapsFormat"
                          :label="
                            googleMapsFormat
                              ? 'Google Maps Format'
                              : 'UTM Koordinaten (EPSG:25832)'
                          "
                          class="mb-4 float-left"
                        >
                        </v-switch>
                        <v-btn
                          text
                          icon
                          big
                          class="float-left"
                          @click="helpDialog = true"
                        >
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-alert
                      v-if="correctMode"
                      outlined
                      type="warning"
                      icon="mdi-progress-wrench"
                      prominent
                      border="left"
                    >
                      <h3>Korrekturmodus</h3>
                      Bitte gib hier die neuen korrekten Google Maps Koordinaten
                      ein: <br />
                    </v-alert>
                    <v-row v-if="googleMapsFormat">
                      <v-col md="12">
                        <v-text-field
                          v-model="mapsCoordinate"
                          label="Google Maps Koordinaten"
                          persistent-placeholder
                          dense
                          number
                          :rules="[mapsCoordinateRule]"
                        />
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="xCoordinate"
                          label="x-Koordinate"
                          persistent-placeholder
                          dense
                          number
                          disabled
                        />
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="yCoordinate"
                          label="y-Koordinate"
                          persistent-placeholder
                          dense
                          number
                          disabled
                        />
                      </v-col>
                    </v-row>
                    <v-row v-if="!googleMapsFormat">
                      <v-col md="6">
                        <v-text-field
                          v-model="xCoordinateUtm"
                          label="x-Koordinate"
                          persistent-placeholder
                          dense
                          number
                          disabled
                        />
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          v-model="yCoordinateUtm"
                          label="y-Koordinate"
                          persistent-placeholder
                          dense
                          number
                          disabled
                        />
                      </v-col>
                      <v-col md="12">
                        <v-text-field
                          v-model="utmCoordinate"
                          label="Postgis &quot;geom&quot;"
                          persistent-placeholder
                          dense
                          number
                          disabled
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn
                  v-if="!correctMode"
                  color="primary"
                  class="mt-4"
                  :disabled="!district"
                  @click="saveNewAddress"
                >
                  Adresse speichern
                </v-btn>
                <v-btn
                  v-if="correctMode"
                  color="warning"
                  class="mt-4"
                  @click="updateCorrectedAddress"
                >
                  Korrektur speichern
                </v-btn>
                <br />
                <v-alert
                  v-if="successMessage"
                  text
                  class="mt-2"
                  type="success"
                  transition="scroll-y-transition"
                >
                  Adresse erfolgreich gespeichert
                </v-alert>
                <v-alert
                  v-if="errorMessage"
                  text
                  class="mt-2"
                  type="error"
                  transition="scroll-y-transition"
                >
                  {{ errorMessageContent }}
                </v-alert>
                <!-- </div> -->
              </v-col>
              <v-col v-if="convertedPoint || (address && address.geo)" md="6">
                <DetailMap
                  v-if="tabs === 1"
                  :point="convertedPoint ? convertedPoint : undefined"
                  :radius="
                    address.geo && !streetGeo
                      ? JSON.parse(address.geo).coordinates
                      : undefined
                  "
                  :radius-small="
                    streetGeo ? JSON.parse(streetGeo).coordinates : undefined
                  "
                  :zoom="mapZoom"
                  :project-id="projectId ? projectId : undefined"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <!-- <pre>
          {{ addressesForVerification }}
        </pre> -->
        <v-btn
          text
          x-small
          @click="showFilter = showFilter ? false : true"
        >
          Filter
          <template v-if="showFilter">
            <v-icon>mdi-menu-up</v-icon>
          </template>
          <template v-else>
            <v-icon>mdi-menu-down</v-icon>
          </template>
        </v-btn>
        <v-card v-if="showFilter" class="filter-card">
          <v-switch
            v-model="showOnlyOwnAddresses"
            inset
            :label="
              showOnlyOwnAddresses
                ? 'selbst angelegte Adressen'
                : 'alle Adressen'
            "
            @change="loadAddressesForVerification"
          ></v-switch>
          <v-radio-group v-model="statusFilter" row>
            <v-radio label="Alle" value="all"></v-radio>
            <v-radio
              label="ungeprüft"
              color="#aaa"
              value="99"
            ></v-radio>
            <v-radio
              label="Korrektur erforderlich"
              color="#ffe100"
              value="98"
            ></v-radio>
            <v-radio
              label="abgelehnt"
              color="#ff6b6b"
              value="97"
            ></v-radio>
            <v-radio
              label="freigeben"
              color="#80ed99"
              value="3"
            ></v-radio>
          </v-radio-group>
        </v-card>
        <v-card v-if="tabs === 2" class="table-card">
          <v-data-table
            id="table1"
            ref="table1"
            :headers="headers"
            :items="addressesForVerificationFiltered"
            :items-per-page="15"
            class="elevation-1 verification-table"
            :loading="loading"
            :sort-by="['date_gt_edited']"
            :sort-desc="true"
            @click:row="showDetails"
          >
            <template v-slot:[`item.addr_gt_class_id`]="{ item }">
              <v-tooltip top :color="getTooltipColor(item.addr_gt_class_id)">
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <div
                      v-if="item && item.addr_gt_class_id === '3'"
                      class="status-green"
                    ></div>
                    <div
                      v-else-if="item && item.addr_gt_class_id === '99'"
                      class="status-grey"
                    ></div>
                    <div
                      v-else-if="item && item.addr_gt_class_id === '98'"
                      class="status-yellow"
                    ></div>
                    <div
                      v-else-if="item && item.addr_gt_class_id === '97'"
                      class="status-red"
                    ></div>
                    <div v-else class="status-grey"></div>
                  </div>
                </template>
                <span>{{ getTooltipText(item.addr_gt_class_id) }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.date_gt_edited`]="{ item }">
              {{ formatDateTime(item.date_gt_edited) }}
              <i v-if="item && item.last_editor" style="font-size: 10px">
                ({{ item.last_editor }})
              </i>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="userGroups.includes('camunda-admin')">
        <v-btn
          text
          x-small
          @click="showFilter = showFilter ? false : true"
        >
          Filter
          <template v-if="showFilter">
            <v-icon>mdi-menu-up</v-icon>
          </template>
          <template v-else>
            <v-icon>mdi-menu-down</v-icon>
          </template>
        </v-btn>
        <v-card v-if="showFilter" class="filter-card d-flex">
          <v-switch
            v-model="showOnlyActiveAddresses"
            inset
            class="mr-4"
            :label="
              showOnlyActiveAddresses
                ? 'nur nicht gekündigte Verträge'
                : 'alle Verträge'
            "
            @change="loadInvalidAddresses"
          ></v-switch>
          <!-- <v-switch
            v-model="showOnly10Addresses"
            inset
            :label="
              showOnly10Addresses
                ? 'nur 10 Adressen anzeigen'
                : 'alle Adressen anzeigen'
            "
            @change="loadInvalidAddresses"
          ></v-switch> -->
          <v-spacer></v-spacer>
          <div>
            <v-text-field
              v-model="searchText"
              class="mt-2"
              placeholder="Nach Ort filtern"
              prepend-inner-icon="mdi-map-search-outline"
              style="width: 300px"
              dense
              rounded
              outlined
              clearable
            >
            </v-text-field>
          </div>
        </v-card>
        <v-card v-if="tabs === 3" class="table-card">
          <v-data-table
            id="table2"
            ref="table2"
            :headers="invalidAddressHeaders"
            :items="filteredInvalidAdresses"
            :items-per-page="15"
            class="elevation-1 verification-table"
            :loading="loading"
            :sort-desc="true"
            @click:row="showInvalidDetails"
          >
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <h3
          style="
            color: #ddd;
            width: 100%;
            text-align: center;
            display: block;
            margin: auto;
          "
        >
          COMING SOON ...
        </h3>
      </v-tab-item>
    </v-tabs>
    <v-dialog
      v-model="projectDialog"
      transition="dialog-bottom-transition"
      max-width="400"
      scrollable
    >
      <v-card v-if="projectId">
        <v-toolbar
          :color="
            projectDistance != undefined && projectDistance == 0
              ? 'success'
              : 'error'
          "
          dark
        >
          <template v-if="projectDistance != undefined && projectDistance == 0">
            Projekt {{ projectId }}
          </template>
          <template v-else>
            Kein Projekt ermittelbar!
          </template>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="projectDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-4" stlye="font-size: 18px;">
          <template v-if="projectDistance != undefined && projectDistance == 0">
            <v-icon>mdi-check</v-icon> Adresse befindet sich innerhalb von
            Projekt <b>{{ projectId }}</b>
          </template>
          <template
            v-else-if="projectDistance != undefined && projectDistance > 0"
          >
            <b>Adresse befindet sich nicht in einem Projektgebiet!</b><br /><br />
            Nächstes Projekt: Projekt-ID <b>{{ projectId }}</b><br />
            <template v-if="projectDistance < 10000">
              Entfernung: {{ projectDistance }} Meter
            </template>
            <template v-else>
              Entfernung:
              {{
                (projectDistance / 1000).toFixed(1).toString().replace('.', ',')
              }}
              km
            </template>
          </template>
          <template v-else>
            Adresse befindet sich leider nicht in einem Projektgebiet!
            {{ projectDistance }}
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="noProjectDialog"
      transition="dialog-bottom-transition"
      max-width="400"
      scrollable
    >
      <v-card v-if="projectId">
        <v-toolbar color="error" dark>
          Kein Projekt ermittelbar!
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="noProjectDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-4" stlye="font-size: 16px;">
          Adresse befindet sich leider nicht in einem Projektgebiet!
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="invalidDetailsVisible"
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card v-if="selectedInvalidAddress">
        <v-toolbar color="primary" dark>
          Details zu Adresse {{ selectedInvalidAddress.ADDRESS_ID }}, Vertrag
          {{ selectedInvalidAddress.CO_ID }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="invalidDetailsVisible = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col md="6">
              <v-card elevation="1">
                <h3>Installationsadresse varioCRM</h3>
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td>PLZ</td>
                      <td>
                        <b v-if="selectedInvalidAddress.CITYCODE">{{
                          selectedInvalidAddress.CITYCODE
                        }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Ort</td>
                      <td>
                        <b v-if="selectedInvalidAddress.CITY">{{
                          selectedInvalidAddress.CITY
                        }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Ortsteil</td>
                      <td>
                        <b v-if="selectedInvalidAddress.DISTRICT">{{
                          selectedInvalidAddress.DISTRICT
                        }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Straße</td>
                      <td>
                        <b v-if="selectedInvalidAddress.STREET">{{
                          selectedInvalidAddress.STREET
                        }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Hausnummer</td>
                      <td>
                        <b v-if="selectedInvalidAddress.HOUSENO">{{
                          selectedInvalidAddress.HOUSENO
                        }}</b>
                      </td>
                    </tr>
                    <tr v-if="selectedInvalidAddress.HOUSENO_SUPPL">
                      <td>Hausnummer-Zusatz</td>
                      <td>
                        <b>{{ selectedInvalidAddress.HOUSENO_SUPPL }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Vertrags-ID:</td>
                      <td>
                        <b>{{ selectedInvalidAddress.CO_ID }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>gekündigt:</td>
                      <td>
                        <b v-if="selectedInvalidAddress.DATE_DEACTIVE">
                          {{ formatDate(selectedInvalidAddress.DATE_DEACTIVE) }}
                        </b>
                        <b v-else> nein </b>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
              <v-btn
                color="primary"
                class="my-6"
                @click="adjustMode ? (adjustMode = false) : (adjustMode = true)"
              >
                Vorschläge anpassen
              </v-btn>
              <template v-if="adjustMode">
                <AddressAutocomplete
                  ref="AddressAutocompleteSuggestions"
                  :disabled="correctMode"
                  :previouslySelectedAddress="selectedInvalidAddressZip"
                  @update="updateAddressSuggestions"
                />
              </template>
              <!-- <v-simple-table>
                <tbody>

                </tbody>
              </v-simple-table> -->
              <!-- <pre>
                {{ selectedAddress }}
              </pre> -->
            </v-col>
            <v-col md="6">
              <v-overlay :value="loading">
                <v-progress-circular
                  :size="120"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </v-overlay>
              <h3>Vorgeschlagene Adressen:</h3>
              <div style="overflow: auto; height: 425px" class="pa-1">
                <template v-for="(suggestion, index) in addressSuggestions">
                  <v-card :key="index" class="mb-2">
                    <v-card-title class="pb-0">
                      {{ suggestion.street }}
                      <b>
                        &nbsp;{{ suggestion.houseno }}
                        <i v-if="suggestion.houseno_suppl">{{
                          suggestion.houseno_suppl
                        }}</i>
                      </b>
                      ,&nbsp;{{ suggestion.zip }} {{ suggestion.city }}
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col
                          v-if="
                            (suggestion.similarity ||
                              suggestion.similarity === 0) &&
                              !adjustMode
                          "
                          md="2"
                        >
                          <v-progress-circular
                            :rotate="-90"
                            :size="65"
                            :width="10"
                            :value="(suggestion.similarity * 100).toFixed(0)"
                            :color="getColor(suggestion.similarity)"
                          >
                            <template>
                              {{ (suggestion.similarity * 100).toFixed(0) }}%
                            </template>
                          </v-progress-circular>
                        </v-col>
                        <v-col>
                          <b class="pr-4">Ortsteil: </b>{{ suggestion.district
                          }}<br />
                          <b class="pr-4">AGS-Key: </b>{{ suggestion.ags27
                          }}<br />
                          <b class="pr-4">Projekt-ID: </b>{{ suggestion.project_id }}
                        </v-col>
                        <v-col md="2">
                          <v-btn
                            class="float-right py-4"
                            color="success"
                            small
                            @click="showConfirmDialog = 'a' + index"
                          >
                            Vorschlag <br />
                            annehmen
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <template v-if="showConfirmDialog == 'a' + index">
                    <v-dialog
                      :key="index + 'i'"
                      v-model="showConfirmDialog"
                      transition="dialog-bottom-transition"
                      width="600"
                      scrollable
                    >
                      <v-card>
                        <v-toolbar color="primary" dark>
                          Adresse in varioCRM speichern
                          <v-spacer></v-spacer>
                          <v-toolbar-items>
                            <v-btn
                              icon
                              dark
                              @click="showConfirmDialog = false"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text class="mt-4" stlye="font-size: 16px;">
                          <h3>
                            Soll folgende Adresse zum Vertrag
                            <i color="primary">{{
                              selectedInvalidAddress.CO_ID
                            }}</i>
                            gespeichert werden?
                          </h3>
                          <br />
                          <v-divider />
                          <h2 class="pa-4">
                            {{ suggestion.street }}
                            &nbsp;{{ suggestion.houseno }}
                            <i v-if="suggestion.houseno_suppl">{{
                              suggestion.houseno_suppl
                            }}</i>
                            ,&nbsp;{{ suggestion.zip }} {{ suggestion.city }}
                          </h2>
                          <v-divider />
                        </v-card-text>
                        <v-card-actions>
                          <v-btn @click="showConfirmDialog = false">
                            Abbrechen
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="success"
                            @click="
                              saveSuggestion(
                                selectedInvalidAddress.ADDRESS_ID,
                                suggestion
                              )
                            "
                          >
                            Adresse speichern
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </template>
              </div>
              <v-divider />
              <h3 class="my-4">
                Adresse nicht gefunden?
              </h3>
              <v-btn
                color="primary"
                @click="
                  (tabs = 1), (invalidDetailsVisible = false), (loading = false)
                "
              >
                Neue Adresse anlegen
              </v-btn>
              <!-- {{ selectedInvalidAddress }} <br />
              <pre>
                {{ addressSuggestions }}
              </pre> -->
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-actions>
          <v-btn
            color="error"
            class="mt-4"
            :disabled="
              userGroups.includes('camunda-admin') ||
                userGroups.includes('LgSpotAddressmaster')
                ? false
                : true
            "
            @click="
              verificateAddress(
                selectedAddress.addr_id,
                97,
                projectIdForVerification,
                projectDistanceForVerification
              )
            "
          >
            Adresse ablehnen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="mt-4"
            :disabled="
              userGroups.includes('camunda-admin') ||
                userGroups.includes('LgSpotAddressmaster')
                ? false
                : true
            "
            @click="
              verificateAddress(
                selectedAddress.addr_id,
                98,
                projectIdForVerification,
                projectDistanceForVerification
              )
            "
          >
            Korrektur anfordern
          </v-btn>
          <v-btn
            color="success"
            class="mt-4"
            :disabled="
              userGroups.includes('camunda-admin') ||
                userGroups.includes('LgSpotAddressmaster')
                ? false
                : true
            "
            @click="
              verificateAddress(
                selectedAddress.addr_id,
                3,
                projectIdForVerification,
                projectDistanceForVerification
              )
            "
          >
            Adresse freigeben
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="detailsVisible"
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card v-if="selectedAddress">
        <v-toolbar color="primary" dark>
          Details zu Adresse {{ selectedAddress.addr_id }}
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="detailsVisible = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col md="6">
              <v-simple-table class="mt-2">
                <tbody>
                  <tr>
                    <td>Status</td>
                    <td>
                      <template v-if="selectedAddress.addr_gt_class_id === '3'">
                        <div class="status-green status-circle"></div>
                        freigegeben
                      </template>
                      <template
                        v-else-if="selectedAddress.addr_gt_class_id === '99'"
                      >
                        <div class="status-grey status-circle"></div>
                        ungeprüft
                      </template>
                      <template
                        v-else-if="selectedAddress.addr_gt_class_id === '98'"
                      >
                        <div class="my-2">
                          <div class="status-yellow status-circle"></div>
                          Korrektur erforderlich
                          <v-btn
                            color="#ffdd00"
                            small
                            class="ma-2"
                            @click="correctAddress(selectedAddress)"
                          >
                            <v-icon>mdi-progress-wrench</v-icon>
                            jetzt korrigieren
                          </v-btn>
                        </div>
                      </template>
                      <template
                        v-else-if="selectedAddress.addr_gt_class_id === '97'"
                      >
                        <div class="status-red status-circle"></div>
                        abgelehnt
                      </template>
                      <template v-else>
                        <div class="status-grey status-circle"></div>
                        unbekannt
                      </template>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-card elevation="1">
                <v-simple-table dense>
                  <tbody>
                    <tr>
                      <td>G-AGS-Key</td>
                      <td>
                        <b>{{ selectedAddress.addr_id }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>PLZ</td>
                      <td>
                        <b>{{ selectedAddress.addr_gt_plz }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Ort</td>
                      <td>
                        <b>{{ selectedAddress.addr_gt_ort }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Ortsteil</td>
                      <td>
                        <b>{{ selectedAddress.addr_gt_ortsteil }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Straße</td>
                      <td>
                        <b>{{ selectedAddress.addr_gt_str }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Hausnummer</td>
                      <td>
                        <b>{{ selectedAddress.addr_gt_hnr }}</b>
                      </td>
                    </tr>
                    <tr v-if="selectedAddress.addr_gt_hnrz">
                      <td>Hausnummer-Zusatz</td>
                      <td>
                        <b>{{ selectedAddress.addr_gt_hnrz }}</b>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>Anzahl Wohneinheiten</td>
                    <td>
                      <b>{{ selectedAddress.addr_gt_hh }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Anzahl Gewerbeeinheiten</td>
                    <td>
                      <b>{{ selectedAddress.addr_gt_gew }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Öffentliches Gebäude</td>
                    <td>
                      <v-icon
                        v-if="selectedAddress.addr_gt_oeff"
                        color="success"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon v-else color="error">
                        mdi-close
                      </v-icon>
                    </td>
                  </tr>
                  <tr>
                    <td>Wohnungswirtschaft</td>
                    <td>
                      <v-icon
                        v-if="selectedAddress.addr_gt_wowi"
                        color="success"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon v-else color="error">
                        mdi-close
                      </v-icon>
                    </td>
                  </tr>
                  <tr v-if="projectIdLoading || projectIdForVerification">
                    <td>Projekt-ID</td>
                    <td>
                      <template v-if="projectIdLoading">
                        <v-icon class="icon-spinner">
                          mdi-vector-circle-variant
                        </v-icon>
                      </template>
                      <template
                        v-else-if="
                          projectIdForVerification &&
                            projectDistanceForVerification === 0 &&
                            !projectIdLoading
                        "
                      >
                        <b>{{ projectIdForVerification }}</b>
                      </template>
                      <template v-else>
                        <v-icon color="error">
                          mdi-close-circle-outline
                        </v-icon>
                        <b> nicht innerhalb eines Projekts</b>
                      </template>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      !projectIdLoading &&
                        projectIdForVerification &&
                        projectDistanceForVerification &&
                        projectDistanceForVerification > 0
                    "
                    style="background-color: rgba(0, 0, 0, 0.05); color: #555"
                  >
                    <td>nächstes Projekt</td>
                    <td>
                      Projekt-ID: <b>{{ projectIdForVerification }}</b><br />
                      Entfernung:
                      <b
                        v-if="projectDistanceForVerification < 5000"
                      >{{ projectDistanceForVerification }} m</b>
                      <b
                        v-else
                      >{{
                        (projectDistanceForVerification / 1000).toFixed(0)
                      }}
                        km</b>
                    </td>
                  </tr>
                  <tr>
                    <td>erstellt am</td>
                    <td>
                      {{ formatDateTime(selectedAddress.date_gt_created) }},
                      {{
                        selectedAddress.addr_src_srctype
                          .replace('SPOT(', '')
                          .replace(')', '')
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>zuletzt bearbeitet</td>
                    <td>
                      {{ formatDateTime(selectedAddress.date_gt_edited) }},
                      {{ selectedAddress.last_editor }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <!-- <pre>
                {{ selectedAddress }}
              </pre> -->
            </v-col>
            <v-col
              v-if="selectedAddress && selectedAddress.st_asgeojson"
              md="6"
            >
              <div class="mt-6">
                <DetailMap
                  :point="JSON.parse(selectedAddress.st_asgeojson).coordinates"
                  :project-id="
                    projectIdForVerification &&
                      (!projectDistanceForVerification ||
                        projectDistanceForVerification < 5000)
                      ? projectIdForVerification
                      : undefined
                  "
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="error"
            class="mt-4"
            :disabled="
              userGroups.includes('camunda-admin') ||
                userGroups.includes('LgSpotAddressmaster')
                ? false
                : true
            "
            @click="
              verificateAddress(
                selectedAddress.addr_id,
                97,
                projectIdForVerification,
                projectDistanceForVerification
              )
            "
          >
            Adresse ablehnen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="mt-4"
            :disabled="
              userGroups.includes('camunda-admin') ||
                userGroups.includes('LgSpotAddressmaster')
                ? false
                : true
            "
            @click="
              verificateAddress(
                selectedAddress.addr_id,
                98,
                projectIdForVerification,
                projectDistanceForVerification
              )
            "
          >
            Korrektur anfordern
          </v-btn>
          <v-btn
            color="success"
            class="mt-4"
            :disabled="
              userGroups.includes('camunda-admin') ||
                userGroups.includes('LgSpotAddressmaster')
                ? false
                : true
            "
            @click="
              verificateAddress(
                selectedAddress.addr_id,
                3,
                projectIdForVerification,
                projectDistanceForVerification
              )
            "
          >
            Adresse freigeben
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DetailMap from './DetailMap.vue';
import AvailabilityForm from './AvailabilityForm.vue';
import ProjectDetails from './ProjectDetails.vue';
import NotificationObject from '@/main/NotificationObject.js';
import AddressAutocomplete from '@/components/orderEntry/orderCreation/customerDataSelection/AddressAutocomplete.vue';
import { HTTP } from '../../main/httpClient';
// import { filter } from 'vue/types/umd';
import proj4 from 'proj4';
// import { fromLonLat } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import axios from 'axios';

proj4.defs(
  'EPSG:25832',
  '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
);

proj4.defs('EPSG:4326', '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs');

register(proj4);

export default {
  name: 'Addressmaster',
  components: {
    DetailMap,
    ProjectDetails,
    AddressAutocomplete,
    AvailabilityForm
  },
  data: () => ({
    homes: 1,
    offices: 0,
    publicBuilding: false,
    wowi: false,
    xCoordinate: undefined,
    yCoordinate: undefined,
    mapsCoordinate: undefined,
    xCoordinateUtm: undefined,
    yCoordinateUtm: undefined,
    utmCoordinate: undefined,
    googleMapsFormat: true,
    mapZoom: 14,
    successMessage: false,
    errorMessage: false,
    errorMessageContent:
      'Beim Speichern der Adresse ist ein Fehler aufgetreten',
    helpDialog: false,
    addressesForVerification: [],
    invalidAddresses: [],
    invalidAddressHeaders: [
      { text: 'Adress-ID', value: 'ADDRESS_ID', width: '90' },
      { text: 'PLZ', value: 'CITYCODE', width: '100' },
      { text: 'Ort', value: 'CITY' },
      { text: 'Ortsteil', value: 'DISTRICT' },
      { text: 'Straße', value: 'STREET' },
      { text: 'Hausnr.', value: 'HOUSENO', width: '100' },
      { text: 'Hausnr.-Zus.', value: 'HOUSENO_SUPPL', width: '150' },
      { text: 'Vertrags-ID', value: 'CO_ID', width: '100' },
      { text: 'G-AGS-Key', value: 'AGS27' }
    ],
    projects: [],
    aggregatedProjects: [],
    projectStrings: [],
    projectKpis: [],
    loading: undefined,
    detailsVisible: false,
    invalidDetailsVisible: false,
    selectedAddress: undefined,
    selectedInvalidAddress: undefined,
    projectId: undefined,
    selectedProject: undefined,
    selectedProjectId: undefined,
    selectedProjectName: undefined,
    distributionStatuses: [],
    constructionStatuses: [],
    selectedDistributionStatus: undefined,
    selectedConstructionStatus: undefined,
    selectedCounty: undefined,
    selectedCity: undefined,
    tariffModels: [],
    selectedTariffModel: undefined,
    searchText: undefined,
    mondayProjects: undefined,
    mondayItems: undefined,
    projectsReady: false,
    mondayReady: false,
    rawProjects: [],
    incompleteProjects: [],
    showIncompleteDialog: false,
    statusFilter: 'all',
    correctMode: false,
    adjustMode: false,
    address: {
      zipCode: null,
      city: null,
      street: null,
      houseNumber: null,
      houseNumberSupplement: null,
      alternativeLocationDescription: null,
      geo: null
    },
    adjustedAddress: {
      zipCode: null,
      city: null,
      street: null,
      houseNumber: null,
      houseNumberSupplement: null,
      alternativeLocationDescription: null,
      geo: null
    },
    showConfirmDialog: false,
    districts: undefined,
    district: undefined,
    streetGeo: undefined,
    propertyResult: undefined,
    showAddressSearch: false,
    showFilter: true,
    errorTolerance: 0,
    noProjectDialog: false,
    projectDialog: false,
    projectDistance: undefined,
    tabs: null,
    projectIdForVerification: undefined,
    projectDistanceForVerification: undefined,
    projectIdLoading: false,
    loadAllAddresses: false,
    showOnlyOwnAddresses: true,
    addressSuggestions: undefined,
    showOnlyActiveAddresses: true,
    showOnly10Addresses: false,
    // convertedPoint: undefined,
    // filtererdProjects: [],
    headers: [
      { text: 'Status', value: 'addr_gt_class_id', width: '85' },
      { text: 'G-AGS-Key', value: 'addr_id' },
      { text: 'PLZ', value: 'addr_gt_plz' },
      { text: 'Ort', value: 'addr_gt_ort' },
      { text: 'Ortsteil', value: 'addr_gt_ortsteil' },
      { text: 'Straße', value: 'addr_gt_str' },
      { text: 'Hausnr.', value: 'addr_gt_hnr', width: '100' },
      { text: 'Hausnr.-Zusatz', value: 'addr_gt_hnrz', width: '100' },
      { text: 'bearbeitet am', value: 'date_gt_edited' }
    ]
    // sortBy: [{ key: 'date_gt_edited', order: 'desc' }],
  }),
  computed: {
    filteredInvalidAdresses() {
      let filtered = [];

      if (this.searchText && isNaN(+this.searchText)) {
        filtered = this.invalidAddresses.filter((item) =>
          item.CITY.toLowerCase().includes(this.searchText.toLowerCase())
        );
      } else {
        filtered = this.invalidAddresses;
      }
      return filtered;
    },
    selectedInvalidAddressZip() {
      let obj;
      if (this.selectedInvalidAddress && this.selectedInvalidAddress.CITYCODE) {
        obj = {
          zipCode: this.selectedInvalidAddress.CITYCODE,
          city: '',
          street: '',
          houseNumber: ''
        };
      } else {
        obj = {
          zipCode: '',
          city: '',
          street: '',
          houseNumber: ''
        };
      }
      return obj;
    },
    addressesForVerificationFiltered() {
      const validNumbers = [3, 97, 98, 99];
      let filtered;
      if (this.statusFilter === 'all') {
        return this.addressesForVerification;
      } else if (validNumbers.includes(parseInt(this.statusFilter))) {
        filtered = this.addressesForVerification.filter(
          (item) =>
            item.addr_gt_class_id.toString() === this.statusFilter.toString()
        );
        console.log('filtered', filtered);
        return filtered;
      } else {
        return this.addressesForVerification;
      }
    },
    userGroups() {
      return this.$auth.user().userRoles;
    },
    convertedPoint() {
      const convertedPoint = [];
      if (
        this.xCoordinate &&
        this.yCoordinate &&
        !isNaN(this.xCoordinate) &&
        !isNaN(this.yCoordinate)
      ) {
        const origin = {
          x: this.xCoordinate,
          y: this.yCoordinate
        };
        const converted = proj4('EPSG:4326', 'EPSG:3857', origin);
        convertedPoint.push(parseFloat(converted.x));
        convertedPoint.push(parseFloat(converted.y));
      }
      if (convertedPoint.length === 2) {
        return convertedPoint;
      } else {
        return undefined;
      }
    },
    convertedPointForSave() {
      const convertedPoint = [];
      if (
        this.xCoordinate &&
        this.yCoordinate &&
        !isNaN(this.xCoordinate) &&
        !isNaN(this.yCoordinate)
      ) {
        const origin = {
          x: this.xCoordinate,
          y: this.yCoordinate
        };
        const converted = proj4('EPSG:4326', 'EPSG:25832', origin);
        convertedPoint.push(parseFloat(converted.x));
        convertedPoint.push(parseFloat(converted.y));
      }
      if (convertedPoint.length === 2) {
        return convertedPoint;
      } else {
        return undefined;
      }
    },
    tableHeaders() {
      let mergedArray;
      const baseArray = [
        { text: 'Projekt-ID', value: 'projektId', class: 'project-id' },
        { text: 'Projektname', value: 'name' },
        { text: 'Ort', value: 'city' },
        { text: 'Ortsteil', value: 'district' },
        { text: 'Landkreis', value: 'county' }
      ];
      const infoArray = [
        // { text: 'Status (intern)', value: 'projectStatus' },
        { text: 'Vertriebsphase', value: 'distributionStatus' },
        { text: 'Kostenträger-Nr', value: 'ktr' },
        // { text: 'Vertriebsphase', value: 'vPhase' },
        { text: 'Baustatus', value: 'constructionStatus' },
        // { text: 'Vorplanung', value: 'vPlan' },
        { text: 'Tarifmodell', value: 'tariffModel.name' },
        // { text: 'Vertriebsstatus', value: 'distributionStatus.description' },
        { text: 'Versandart', value: 'shippingMethod.description' }
      ];
      const kpiArray = [
        { text: 'BP+ (Plan)', value: 'buildingsPassedPlusPlan' },
        { text: 'HP (Plan)', value: 'homesPassedPlan' },
        { text: 'Aufträge (ist)', value: 'contractCount' },
        // { text: 'Homes Activated (Pay)', value: 'homesActivatedPay' },
        { text: 'BP+ Quote', value: 'buildingsPassedQuota' },
        { text: 'HP Quote', value: 'homesPassedQuota' }
      ];
      if (this.projectKpis && this.projectKpis.length) {
        mergedArray = [...baseArray, ...kpiArray, ...infoArray];
      } else {
        mergedArray = [...baseArray, ...infoArray];
      }
      return mergedArray;
    }
  },
  watch: {
    mapsCoordinate(newVal) {
      const regex = new RegExp(/^[0-9]*\.[0-9]+,\s[0-9]*\.[0-9]+$/);
      if (regex.test(newVal)) {
        const splitted = newVal.split(', ');
        this.yCoordinate = parseFloat(splitted[0]);
        this.xCoordinate = parseFloat(splitted[1]);
        this.getProjectAreaNearbyPoint();
      }
    },
    xCoordinateUtm(newVal) {
      if (this.yCoordinateUtm && newVal) {
        this.utmCoordinate =
          'POINT(' + newVal + ' ' + this.yCoordinateUtm + ')';
      }
    },
    yCoordinateUtm(newVal) {
      if (this.yCoordinateUtm && newVal) {
        this.utmCoordinate =
          'POINT(' + this.yCoordinateUtm + ' ' + newVal + ')';
      }
    },
    homes(newVal) {
      if (newVal < 1 && this.offices < 1) {
        this.offices = 1;
      }
    },
    offices(newVal) {
      if (newVal < 1 && this.homes < 1) {
        this.homes = 1;
      }
    },
    'address.street'(newVal) {
      if (!newVal) {
        return;
      }
      if (newVal.length >= 2) {
        this.getStreetCoordinate(
          this.address.zipCode,
          this.address.city,
          newVal
        );
      }
    },
    'address.city'(newVal) {
      if (!newVal) {
        return;
      }
      if (newVal.length >= 2 && !this.address.street) {
        this.streetGeo = undefined;
      }
    },
    successMessage(newVal) {
      if (newVal) {
        const _this = this;
        setTimeout(function () {
          _this.successMessage = false;
        }, 2500);
      }
    },
    errorMessage(newVal) {
      if (newVal) {
        const _this = this;
        setTimeout(function () {
          _this.errorMessage = false;
        }, 2500);
      }
    },
    showOnlyActiveAddresses() {
      this.loadInvalidAddresses();
    },
    showOnly10Addresses() {
      this.loadInvalidAddresses();
    }
  },
  beforeMount() {
    this.loading = true;
  },
  async mounted() {
    this.loading = true;
  },
  methods: {
    getProjectAreaNearbyPoint(point) {
      let formattedPoint;
      if (point) {
        this.projectIdLoading = true;
        formattedPoint = 'POINT(' + point.toString().replace(',', ' ') + ')';
      } else {
        formattedPoint =
          'POINT(' + this.convertedPoint.toString().replace(',', ' ') + ')';
      }

      HTTP.get('/past/geo/project-area-nearby-point?point=' + formattedPoint)
        .then((response) => {
          if (response && response.data && response.data.projekt_id) {
            if (point) {
              this.projectIdForVerification = response.data.projekt_id;
              this.projectDistanceForVerification = response.data.distance;
              this.projectIdLoading = false;
            } else {
              this.projectId = response.data.projekt_id;
              this.projectDistance = response.data.distance;
              this.projectDialog = true;
            }
          } else {
            if (!point) {
              this.noProjectDialog = true;
            }
          }
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
          this.projectIdLoading = false;
        });
    },
    formatPointArray(point) {
      const convertedPoint = [];
      if (point && point.includes('POINT(')) {
        const pointArray = point
          .replace('POINT(', '')
          .replace(')', '')
          .split(' ');
        convertedPoint.push(parseFloat(pointArray[0]));
        convertedPoint.push(parseFloat(pointArray[1]));
      }
      return convertedPoint;
    },
    getCollection(collection) {
      this.loading = true;
      return HTTP.get('/past/' + collection)
        .then((response) => {
          this.loading = false;
          return response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getDistricts() {
      this.loading = true;
      if (
        this.address &&
        this.address.zipCode &&
        this.address.city &&
        this.address.street
      ) {
        HTTP.get(
          '/past/geo/property-address?zip=' +
            this.address.zipCode +
            '&city=' +
            this.address.city +
            '&street=' +
            this.address.street
        )
          .then((response) => {
            this.loading = false;
            if (response && response.data && response.data.districts) {
              this.districts = response.data.districts;
              if (response.data.districts[0]) {
                this.district = response.data.districts[0];
              }
            }
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },
    getProperty() {
      this.loading = true;
      const zipCode =
        this.address && this.address.zipCode ? this.address.zipCode : '';
      const city = this.address && this.address.city ? this.address.city : '';
      const street =
        this.address && this.address.street ? this.address.street : '';
      const houseNumber =
        this.address && this.address.houseNumber
          ? this.address.houseNumber
          : '';
      const houseNumberSupplement =
        this.address && this.address.houseNumberSupplement
          ? this.address.houseNumberSupplement
          : '';
      const errorToleranceMeters =
        this.errorTolerance && !isNaN(+this.errorTolerance)
          ? parseInt(this.errorTolerance)
          : 0;

      const queryString =
        '?zip=' +
        zipCode +
        '&city=' +
        city +
        '&street=' +
        street +
        '&houseno=' +
        houseNumber +
        '&errorToleranceMeters=' +
        errorToleranceMeters +
        '&housenoSuppl=' +
        houseNumberSupplement +
        '&similarity=100';

      HTTP.get('/past/geo/property-status' + queryString)
        .then((response) => {
          this.propertyResult = response.data ? response.data : undefined;
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'warning',
              'Fehlerhafte Adresse oder Adresse bereits vorhanden'
            )
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveNewAddress() {
      const coordinateObject = {
        type: 'Point',
        coordinates: this.convertedPointForSave
      };

      const newAddressObject = {
        zip: this.address.zipCode,
        city: this.address.city,
        street: this.address.street,
        houseno: this.address.houseNumber,
        housenoSuppl: this.address.houseNumberSupplement
          ? this.address.houseNumberSupplement
          : null,
        district: this.district?.addr_gt_ortsteil,
        ags11: this.district?.addr_src_ags11,
        homes: this.homes ? this.homes : 0,
        offices: this.offices ? this.offices : 0,
        publicBuilding: this.publicBuilding ? 1 : 0,
        wowi: this.wowi ? 1 : 0,
        coordinates: coordinateObject,
        editor: this.$auth.user().userName
      };

      if (
        this.address &&
        this.address.zipCode &&
        this.address.city &&
        this.address.street &&
        this.address.houseNumber &&
        this.convertedPointForSave &&
        newAddressObject &&
        newAddressObject.ags11
      ) {
        axios
          .post('https://spot.goetel.de/save-new-address', newAddressObject, {
            headers: {
              Authorization: 'Basic cGFzdGFkbWluOjhOcDkyalYxcGU=',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
          })
          .then((response) => {
            this.$store.commit(
              'addNotification',
              new NotificationObject('success', 'Neue Adresse gespeichert')
            );
            this.address = {
              zipCode: null,
              city: null,
              street: null,
              houseNumber: null,
              houseNumberSupplement: null,
              alternativeLocationDescription: null,
              geo: null
            };
            this.district = undefined;
            this.mapsCoordinate = undefined;
            this.homes = 1;
            this.offices = 0;
            this.publicBuilding = false;
            this.wowi = false;
            this.successMessage = true;
            this.$refs.AddressAutocomplete.clearAddress();
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            console.log(error.response.data);
            if (error.response && error.response.data) {
              this.errorMessageContent = error.response.data;
            } else {
              this.errorMessageContent =
                'Beim Speichern der Adresse ist ein Fehler aufgetreten';
            }
            this.$store.commit(
              'addNotification',
              new NotificationObject('warning', this.errorMessageContent)
            );
            this.errorMessage = true;
            // HTTP.post('/past/geo/save-new-address', newAddressObject)
            //   .then((response) => {
            //     this.$store.commit(
            //       'addNotification',
            //       new NotificationObject('success', 'Neue Adresse gespeichert')
            //     );
            //     this.address = {
            //       zipCode: null,
            //       city: null,
            //       street: null,
            //       houseNumber: null,
            //       houseNumberSupplement: null,
            //       alternativeLocationDescription: null,
            //       geo: null
            //     };
            //     this.district = undefined;
            //     this.mapsCoordinate = undefined;
            //     this.homes = 1;
            //     this.offices = 0;
            //     this.publicBuilding = false;
            //     this.wowi = false;
            //     this.successMessage = true;
            //     this.$refs.AddressAutocomplete.clearAddress();
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //     this.$store.commit(
            //       'addNotification',
            //       new NotificationObject(
            //         'warning',
            //         'Feherhafte Adresse oder kein Ergebnis'
            //       )
            //     );
            //     this.errorMessage = true;
            //   })
            //   .finally(() => {
            //     this.loading = false;
            //   });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.commit(
          'addNotification',
          new NotificationObject('error', 'Adresse unvollständig')
        );
      }
    },
    loadAddressesForVerification() {
      let creatorString = '';

      if (this.showOnlyOwnAddresses) {
        creatorString = '?creator=' + this.$auth.user().userName;
      }

      HTTP.get('/past/geo/addresses-for-verification' + creatorString)
        .then((response) => {
          this.addressesForVerification = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addNotification',
            new NotificationObject('warning', 'Es ist ein Fehler aufgetreten')
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadInvalidAddresses() {
      let queryString;
      if (this.showOnly10Addresses) {
        queryString = '?limit=10&onlyActive=' + this.showOnly10Addresses;
      } else {
        queryString = '?onlyActive=' + this.showOnlyActiveAddresses;
      }
      this.loading = true;
      HTTP.get('/past/geo/invalid-address-queue' + queryString)
        .then((response) => {
          this.invalidAddresses = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addNotification',
            new NotificationObject('warning', 'Es ist ein Fehler aufgetreten')
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadAddressSuggestions() {
      let streetString = '';
      let zipString = '';
      let cityString = '';
      let housenoString = '';
      let housenoSupplString = '';

      if (
        (this.adjustMode &&
          this.adjustedAddress &&
          this.adjustedAddress.city &&
          this.adjustedAddress.street) ||
        (this.selectedInvalidAddress &&
          this.selectedInvalidAddress.STREET &&
          (this.selectedInvalidAddress.CITY ||
            this.selectedInvalidAddress.CITYCODE))
      ) {
        if (
          this.adjustMode &&
          this.adjustedAddress &&
          this.adjustedAddress.city &&
          this.adjustedAddress.street
        ) {
          streetString = '?street=' + this.adjustedAddress.street;

          zipString = this.adjustedAddress.zipCode
            ? '&zip=' + this.adjustedAddress.zipCode
            : '';
          cityString = this.adjustedAddress.city
            ? '&city=' + this.adjustedAddress.city
            : '';
          housenoString = this.adjustedAddress.houseNumber
            ? '&houseno=' + this.adjustedAddress.houseNumber
            : '';
          housenoSupplString = this.adjustedAddress.houseNumberSupplement
            ? '&housenoSuppl=' + this.adjustedAddress.houseNumberSupplement
            : '';
        } else if (
          this.selectedInvalidAddress &&
          this.selectedInvalidAddress.STREET &&
          (this.selectedInvalidAddress.CITY ||
            this.selectedInvalidAddress.CITYCODE)
        ) {
          streetString = '?street=' + this.selectedInvalidAddress.STREET;

          zipString = this.selectedInvalidAddress.CITYCODE
            ? '&zip=' + this.selectedInvalidAddress.CITYCODE
            : '';
          cityString = this.selectedInvalidAddress.CITY
            ? '&city=' + this.selectedInvalidAddress.CITY
            : '';
          housenoString = this.selectedInvalidAddress.HOUSENO
            ? '&houseno=' + this.selectedInvalidAddress.HOUSENO
            : '';
          housenoSupplString = this.selectedInvalidAddress.HOUSENO_SUPPL
            ? '&housenoSuppl=' + this.selectedInvalidAddress.HOUSENO_SUPPL
            : '';
        }

        const addressLimit =
          this.adjustMode && this.adjustedAddress && this.adjustedAddress.street
            ? '&suggestions=10'
            : '';

        const queryString =
          streetString +
          zipString +
          cityString +
          housenoString +
          housenoSupplString +
          addressLimit;

        this.loading = true;

        HTTP.get('/past/geo/suggested-addresses' + queryString)
          .then((response) => {
            this.addressSuggestions = response.data;
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit(
              'addNotification',
              new NotificationObject('warning', 'Es ist ein Fehler aufgetreten')
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        console.log('Adresse unvollständig');
      }
    },
    updateAddress(address) {
      console.log(address);
      this.address = Object.assign(this.address, address.value);
      this.getDistricts();
    },
    updateAddressSuggestions(address) {
      console.log(address);
      this.adjustedAddress = Object.assign(this.adjustedAddress, address.value);
      this.loadAddressSuggestions();
    },
    updateGeo(geo) {
      this.address.geo = geo;
      this.mapZoom = 13;
    },
    saveSuggestion(addressId, suggestion) {
      if (addressId && suggestion) {
        const addressForUpdate = {
          addressId: addressId,
          zip: suggestion.zip,
          city: suggestion.city,
          district: suggestion.district,
          street: suggestion.street,
          houseNumber: suggestion.houseno,
          houseNumberSupplement: suggestion.houseno_suppl
            ? suggestion.houseno_suppl
            : null,
          gAgs: suggestion.ags27,
          projectId: suggestion.project_id,
          lastEditor: this.$auth.user().userName
        };

        this.loading = true;

        HTTP.get('/past/geo/update-installation-address', {
          params: addressForUpdate
        })
          .then((resp) => {
            if (resp && resp.data) {
              this.invalidDetailsVisible = false;
              this.$store.commit(
                'addNotification',
                new NotificationObject('success', resp.data.message)
              );
              this.loadInvalidAddresses();
            } else if (resp) {
              this.$store.commit(
                'addNotification',
                new NotificationObject('error', resp)
              );
            }
          })
          .catch((err) =>
            this.$store.commit(
              'addNotification',
              new NotificationObject('error', err.response.data)
            )
          )
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.commit(
          'addNotification',
          new NotificationObject('error', 'Adresse unvollständig')
        );
      }
    },
    getStreetCoordinate(zip, city, street) {
      this.loading = true;
      if (zip && city && street) {
        HTTP.get(
          '/past/geo/property-address?city=' +
            city +
            '&zip=' +
            zip +
            '&street=' +
            street
        )
          .then((response) => {
            if (
              response.data &&
              response.data.geojson3857 &&
              response.data.geojson3857.length
            ) {
              this.streetGeo = response.data.geojson3857;
              this.mapZoom = 16;
            }
          })
          .catch((error) => console.log(error))
          .finally(() => (this.loading = false));
      }
    },
    showDetails(item) {
      this.selectedAddress = item;
      this.detailsVisible = true;
      this.getProjectAreaNearbyPoint(JSON.parse(item.st_asgeojson).coordinates);
    },
    showInvalidDetails(item) {
      this.selectedInvalidAddress = item;
      this.loadAddressSuggestions();
      this.invalidDetailsVisible = true;
    },
    showHelper() {
      alert('shwohelper');
      console.log(this);
      this.$root.invalidDetailsVisible = true;
    },
    verificateAddress(gAgs, addrClassId, projectId, distance) {
      // verificateAddress(selectedAddress.addr_id, 0, projectIdForVerification, projectDistanceForVerification
      this.loading = true;
      let projectString = '';
      if (!addrClassId && addrClassId !== 0) {
        addrClassId = 99;
      }
      if (projectId && ((distance && distance === 0) || !distance)) {
        projectString = '&projectId=' + projectId;
      }
      if (gAgs && gAgs.length === 28) {
        HTTP.get(
          '/past/geo/verificate-address?gAgs=' +
            gAgs +
            '&addrClassId=' +
            addrClassId +
            '&editor=' +
            this.$auth.user().userName +
            projectString
        )
          .then((response) => {
            this.loadAddressesForVerification();
            this.$store.commit(
              'addNotification',
              new NotificationObject('success', 'Adresse gespeichert')
            );
            this.detailsVisible = false;
          })
          .catch((error) => {
            console.log(error);
            this.$store.commit(
              'addNotification',
              new NotificationObject('warning', 'Es ist ein Fehler aufgetreten')
            );
          })
          .finally(() => (this.loading = false));
      }
    },
    // validation rules
    mapsCoordinateRule: (v) => {
      if (!v) return 'Bitte geben Sie Koordinaten ein';
      if (!(v && /^[0-9]*\.[0-9]+,\s[0-9]*\.[0-9]+$/).test(v)) {
        return 'Bitte geben Sie die Koordinaten im Google Maps Format an, z.B. "51.54878, 9.90682"'; // const regex = new RegExp(/^[0-9]*\.[0-9]+,\s[0-9]*\.[0-9]+$/);
      }
      return true;
    },
    formatDate(value) {
      // const date = new Date(value);
      // return date.getDate() + '.' + date.getFullMonth() + '.' + date.getFullYear();
      const date = new Date(value);
      const result = date.toLocaleDateString('de-DE', {
        // you can use undefined as first argument
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      return result;
    },
    formatDateTime(value) {
      // const date = new Date(value);
      // return date.getDate() + '.' + date.getFullMonth() + '.' + date.getFullYear();
      const date = new Date(value);
      const result = date.toLocaleTimeString('de-DE', {
        // you can use undefined as first argument
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      return result;
    },
    getTooltipColor(id) {
      let color;
      switch (id) {
        case '3':
          color = '#56d373';
          break;
        case '99':
          color = '#555';
          break;
        case '98':
          color = '#886f00';
          break;
        case '97':
          color = '#ff6b6b';
          break;
        default:
          color = '#555';
      }
      return color;
    },
    getTooltipText(id) {
      let text;
      switch (id) {
        case '3':
          text = 'freigegeben';
          break;
        case '99':
          text = 'ungeprüft';
          break;
        case '98':
          text = 'Korrektur erforderlich';
          break;
        case '97':
          text = 'abgelehnt';
          break;
        default:
          text = 'ungeprüft';
      }
      return text;
    },
    correctAddress(selectedAddress) {
      let editor;
      if (selectedAddress && selectedAddress.addr_src_srctype) {
        editor = selectedAddress.addr_src_srctype
          .replace('SPOT(', '')
          .replace(')', '');
      }

      if (
        editor === this.$auth.user().userName ||
        this.userGroups.includes('camunda-admin') ||
        this.userGroups.includes('LgSpotAddressmaster')
      ) {
        this.detailsVisible = false;
        this.tabs = 1;
        this.correctMode = true;

        const addressObject = {
          zipCode: selectedAddress.addr_gt_plz,
          city: selectedAddress.addr_gt_ort,
          street: selectedAddress.addr_gt_str,
          houseNumber: selectedAddress.addr_gt_hnr,
          houseNumberSupplement: selectedAddress.addr_gt_hnrz
        };

        this.address = {
          zipCode: null,
          city: null,
          street: null,
          houseNumber: null,
          houseNumberSupplement: null,
          alternativeLocationDescription: null,
          geo: null
        };

        Object.assign(this.address, addressObject);

        this.homes =
          selectedAddress && selectedAddress.addr_gt_hh
            ? selectedAddress.addr_gt_hh
            : 0;
        this.offices =
          selectedAddress && selectedAddress.addr_gt_gew
            ? selectedAddress.addr_gt_gew
            : 0;
        this.publicBuilding = !!(
          selectedAddress && selectedAddress.addr_gt_oeff
        );
        this.wowi = !!(selectedAddress && selectedAddress.addr_gt_wowi);
      } else {
        alert('Es können nur selbst erstellte Adressen korrigiert werden.');
      }
    },
    updateCorrectedAddress() {
      if (this.selectedAddress && this.selectedAddress.addr_id) {
        const coordinateObject = {
          type: 'Point',
          coordinates: this.convertedPointForSave
        };

        const correctedObject = {
          addr_id: this.selectedAddress.addr_id,
          coordinates: coordinateObject,
          editor: this.$auth.user().userName
        };

        axios
          .post('https://spot.goetel.de/update-new-address', correctedObject, {
            headers: {
              Authorization: 'Basic cGFzdGFkbWluOjhOcDkyalYxcGU=',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods':
                'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
          })
          .then((response) => {
            this.$store.commit(
              'addNotification',
              new NotificationObject('success', 'Korrektur gespeichert')
            );
            this.address = {
              zipCode: null,
              city: null,
              street: null,
              houseNumber: null,
              houseNumberSupplement: null,
              alternativeLocationDescription: null,
              geo: null
            };
            this.district = undefined;
            this.mapsCoordinate = undefined;
            this.homes = 1;
            this.offices = 0;
            this.publicBuilding = false;
            this.wowi = false;
            this.successMessage = true;
            this.$refs.AddressAutocomplete.clearAddress();
            this.correctMode = false;
          })
          .catch((error) => {
            console.log(error);
            console.log(error.response);
            console.log(error.response.data);
            if (error.response && error.response.data) {
              this.errorMessageContent = error.response.data;
            } else {
              this.errorMessageContent =
                'Beim Speichern ist ein Fehler aufgetreten';
            }
            this.$store.commit(
              'addNotification',
              new NotificationObject('warning', this.errorMessageContent)
            );
            this.errorMessage = true;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.commit(
          'addNotification',
          new NotificationObject(
            'error',
            'Keine Berechtigung oder fehlende Daten'
          )
        );
      }
    },
    getColor(item) {
      const qoute = item * 100;
      let color;
      if (qoute > 60) {
        color = '#20b200';
      } else if (qoute > 45) {
        color = '#96b200';
      } else if (qoute > 30) {
        color = '#d5d100';
      } else if (qoute > 15) {
        color = '#d58600';
      } else {
        color = '#b20000';
      }
      return color;
    }
  }
};
</script>
<style lang="scss" scoped>
.filter-card ::v-deep .v-text-field__details {
  display: none !important;
}
.project-id {
  color: #ff0000;
}
h2 {
  font-size: 24px;
  color: #209bd8;
}
// .row ::v-deep .v-text-field__details {
//   // display: none !important;
// }
.project-table th {
  padding: 0 10px;
}
.v-text-field--outlined.v-select.v-select--chips ::v-deep input {
  min-height: auto;
}
.address-input {
  margin: 10px;
  .v-card__title {
    border-bottom: 1px solid #ddd;
  }
}
.small-card {
  margin-bottom: 10px;
  .v-card__title {
    font-size: 16px;
    color: #555;
    border-bottom: 1px solid #ddd;
    padding: 5px 16px;
    margin-bottom: 20px;
  }
}
.filter-card {
  clear: both;
  margin: -1px 10px -5px 10px;
  padding: 1px 10px 0px 15px;
}
.table-card {
  margin: 15px 10px;
}
.verification-table,
table {
  .status-green {
    width: 30px;
    height: 30px;
    background-color: #80ed99;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #80ed99;
  }
  .status-red {
    width: 30px;
    height: 30px;
    background-color: #ff6b6b;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #ff6b6b;
  }
  .status-yellow {
    width: 30px;
    height: 30px;
    background-color: #ffe100;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #ffe100;
  }
  .status-grey {
    width: 30px;
    height: 30px;
    background-color: #aaa;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #aaa;
  }
}
.status-circle {
  float: left;
  margin-right: 15px;
  width: 20px !important;
  height: 20px !important;
}
.icon-spinner {
  animation: spin-animation 1.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.v-dialog table tr td:first-child {
  width: 50%;
}
</style>
