<template>
  <div class="mt-2">
    <template v-if="!editProject">
      <v-row v-if="(project && project.projektId) || projectIntern">
        <v-col md="6">
          <v-simple-table>
            <tbody>
              <tr>
                <td>Projekt-ID</td>
                <td>
                  <b>{{ projectIntern.projektId }}</b>
                </td>
              </tr>
              <tr>
                <td>Name</td>
                <td>
                  <b>{{ projectIntern.name }}</b>
                </td>
              </tr>
              <tr>
                <td>Ort</td>
                <td>{{ projectIntern.city }}</td>
              </tr>
              <tr>
                <td>Ortsteil</td>
                <td>{{ projectIntern.district }}</td>
              </tr>
              <tr>
                <td>Landkreis</td>
                <td>{{ projectIntern.county }}</td>
              </tr>
              <tr>
                <td>Vertriebsphase</td>
                <td v-if="projectIntern.distributionStatus">
                  <b :style="{ color: projectIntern.distributionStatus.color }">
                    {{ projectIntern.distributionStatus.description }}
                  </b>
                </td>
                <td v-else>
                  -
                </td>
              </tr>
              <!-- <tr>
                <td>Baustatus</td>
                <td v-if="projectIntern.constructionStatus">
                  <b :style="{ color: projectIntern.constructionStatus.color }">
                    {{ projectIntern.constructionStatus.description }}
                  </b>
                </td>
                <td v-else>
                  -
                </td>
              </tr> -->
              <tr>
                <td>Projektstatus</td>
                <td v-if="projectIntern.projectStatus">
                  <b :style="{ color: projectIntern.projectStatus.color }">
                    {{ projectIntern.projectStatus.description }}
                  </b>
                </td>
                <td v-else>
                  -
                </td>
              </tr>
              <tr>
                <td>Bausteuerungstool</td>
                <td v-if="projectIntern.constructionManagementTool">
                  <b>
                    {{ projectIntern.constructionManagementTool }}
                  </b>
                </td>
                <td v-else>
                  -
                </td>
              </tr>
              <tr>
                <td>Tarifmodell</td>
                <td v-if="projectIntern.tariffModel">
                  {{ projectIntern.tariffModel.name }}
                </td>
                <td v-else>
                  -
                </td>
              </tr>
              <tr>
                <td>2Gbit</td>
                <td
                  v-if="
                    projectIntern.technologyStatus &&
                      projectIntern.technologyStatus[0] &&
                      projectIntern.technologyStatus[0].technology &&
                      projectIntern.technologyStatus[0].status &&
                      projectIntern.technologyStatus[0].status.description
                  "
                >
                  <v-icon
                    v-if="
                      projectIntern.technologyStatus[0].status.name ===
                        '2g-ready' ||
                        projectIntern.technologyStatus[0].status.name === 'active'
                    "
                    color="success"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else-if="
                      projectIntern.technologyStatus[0].status.name ===
                        'not-available'
                    "
                    color="error"
                  >
                    mdi-close
                  </v-icon>
                  <v-icon v-else color="warning">
                    mdi-help-circle
                  </v-icon>
                  {{ projectIntern.technologyStatus[0].status.description }}
                </td>
                <td v-else>
                  -
                </td>
              </tr>
              <tr>
                <td>Versandart Hardware</td>
                <td v-if="projectIntern.shippingMethod">
                  {{ projectIntern.shippingMethod.name }} ({{
                    projectIntern.shippingMethod.description
                  }})
                </td>
                <td v-else>
                  -
                </td>
              </tr>
              <tr>
                <td>FTTC Sofortversorgung möglich</td>
                <td>
                  <v-icon
                    v-if="projectIntern.fttcInstantSupply"
                    color="success"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon v-else color="error">
                    mdi-close
                  </v-icon>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-alert
            v-if="statisticData"
            color="tertiary"
            class="kpi-alert statistic-display"
            border="left"
            text
            prominent
          >
            <h3>Statistikdaten</h3>
            <v-simple-table class="number-table px-4">
              <tbody>
                <tr>
                  <td><b>Anzahl FTTH-Aufträge</b></td>
                  <td>{{ statisticData.contractCount }}</td>
                </tr>
                <tr>
                  <td><b>BP+ (Plan)</b><small> (Adressen)</small></td>
                  <td>{{ statisticData.buildingsPassedPlusPlan }}</td>
                </tr>
                <tr>
                  <td><b>BP+ Quote</b></td>
                  <td>
                    {{ formatPercent(statisticData.buildingsPassedQuota) }}
                  </td>
                </tr>
                <tr>
                  <td><b>HP (Plan)</b><small> (Wohneinheiten)</small></td>
                  <td>{{ statisticData.homesPassedPlan }}</td>
                </tr>
                <tr>
                  <td><b>HP Quote</b></td>
                  <td>{{ formatPercent(statisticData.homesPassedQuota) }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-alert>
          <!-- <div class="text-center">
            <v-btn
              class="mt-16"
              large
              @click="editProject = true"
            >
              <v-icon>mdi-playlist-edit</v-icon> Projekt bearbeiten
            </v-btn>
          </div> -->
        </v-col>
        <v-col md="6">
          <DetailMap :project-id="projectIntern.projektId" />
          <KpiDisplay
            v-if="kpis && kpis.length"
            :kpi="kpis"
            mode="alert"
          />
          <div
            v-if="
              this.$auth
                .user()
                .userRoles.some(
                  (element) =>
                    ['camunda-admin', 'LgSpotMarketingAnalyse'].indexOf(
                      element
                    ) >= 0
                )
            "
          >
            <v-btn
              v-if="!hideCleverReachButton"
              color="primary"
              class="my-6 mx-auto"
              @click="generateCleverReachList"
            >
              CleverReach Verteilerliste Generieren
              {{
                numberOfAddressesInCleverReachList !== undefined
                  ? '(' + numberOfAddressesInCleverReachList + ' Adressen)'
                  : ''
              }}
            </v-btn>
            <v-btn
              v-if="!hideBrevoButton"
              color="primary"
              class="mb-6 mx-auto"
              @click="generateBrevoList"
            >
              Brevo Verteilerlisten Generieren
              {{
                brevoListContactCounts !== undefined
                  ? '(' +
                    brevoListContactCounts.receiverCount +
                    ' und ' +
                    brevoListContactCounts.advertisingReceiverCount +
                    ' Adressen)'
                  : ''
              }}
            </v-btn>
          </div>
          <div
            v-if="
              this.$auth
                .user()
                .userRoles.some(
                  (element) =>
                    ['camunda-admin', 'LgSpotGnc'].indexOf(element) >= 0
                )
            "
          >
            <v-btn
              color="warning"
              class="my-2 mx-auto"
              @click="tkiExport"
            >
              <v-icon>mdi-bulldozer</v-icon>
              Adressen zu TKI Exportieren
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </template>
    <!-- <template v-else-if="editProject">
      <EditProject :project-id="projectIntern.projektId" @showDetailView="showDetailView" />
    </template> -->
  </div>
</template>

<script>
import DetailMap from './DetailMap.vue';
import KpiDisplay from './KpiDisplay.vue';
import { HTTP } from '../../main/httpClient';
import axios from 'axios';
// import EditProject from './EditProject.vue';

import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'ProjectDetails',
  components: {
    DetailMap,
    KpiDisplay
    // EditProject
  },
  props: {
    project: {
      type: Object,
      required: false,
      default: undefined
    },
    projectId: {
      type: [Number, String],
      required: false,
      default: undefined
    },
    hideCleverReachButton: {
      type: Boolean,
      required: false,
      default: false
    },
    hideBrevoButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    loading: false,
    kpis: undefined,
    projectIntern: undefined,
    numberOfAddressesInCleverReachList: undefined,
    statisticData: undefined,
    editProject: false,
    brevoListContactCounts: undefined
  }),
  watch: {
    project: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.projektId) {
          this.getKpis(newVal.projektId);
          this.getProjectStatisticData(newVal.projektId);
          this.projectIntern = newVal;
          this.editProject = false;
        } else if (this.projectId) {
          this.getProject(this.projectId);
          this.getKpis(this.projectId);
          this.getProjectStatisticData(this.projectId);
          this.editProject = false;
        }
        this.getNumberOfEmailsForCleverReachList();
        this.getNumberOfEmailsForBrevoList();
      }
    },
    projectId(newVal) {
      if (newVal) {
        this.getKpis(newVal);
        this.getProject(newVal);
        this.getProjectStatisticData(newVal);
        this.editProject = false;
      }
    }
  },
  mounted() {
    if (this.project && this.project.projektId) {
      this.getProject(this.project.projektId);
      this.getKpis(this.project.projektId);
      // this.projectIntern = this.project;
      this.getProjectStatisticData(this.project.projektId);
    } else if (this.projectId) {
      this.getProject(this.projectId);
      this.getKpis(this.projectId);
      this.getProjectStatisticData(this.projectId);
    }
    this.getNumberOfEmailsForCleverReachList();
    this.getNumberOfEmailsForBrevoList();
  },
  methods: {
    showDetailView() {
      this.editProject = false;
    },
    getKpis(projektId) {
      this.loading = true;
      HTTP.get('/past/geo/area-kpi?projektId=' + projektId, {
        headers: this.headers
      })
        .then((response) => {
          this.kpis = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    getProject(projektId) {
      this.loading = true;
      HTTP.get('/past/projects?where[projektId][equals]=' + projektId)
        .then((response) => {
          this.projectIntern = response.data[0];
          this.$emit('projectFetched', this.projectIntern);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    getProjectStatisticData(projektId) {
      this.loading = true;
      HTTP.get(
        '/past/projectStatisticCaches?where[projektId][equals]=' + projektId
      )
        .then((response) => {
          if (response && response.data && response.data.length) {
            this.statisticData = response.data[0];
            this.$emit('projectStatisticCachesFetched', this.statisticData);
          }
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    formatPercent(value) {
      if (value && parseFloat(value) !== isNaN) {
        return (parseFloat(value) * 100).toFixed(2) + '%';
      } else {
        return '-';
      }
    },
    generateCleverReachList() {
      if (this.hideCleverReachButton) return;
      HTTP.post(
        '/cleverreach/generateEmailGroupForProject/' + this.getProjectId()
      ).catch((error) => {
        console.log(error);
        this.$store.commit(
          'addNotification',
          new NotificationObject('error', error.response.data)
        );
      });
    },
    getNumberOfEmailsForCleverReachList() {
      if (this.hideCleverReachButton) return;
      this.loading = true;
      HTTP.get('/cleverreach/numberOfReceiversInProject/' + this.getProjectId())
        .then((response) => {
          this.numberOfAddressesInCleverReachList = response.data.receiverCount;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    generateBrevoList() {
      if (this.hideBrevoButton) return;
      HTTP.post(
        '/brevo/generateEmailListsForProject/' + this.getProjectId()
      ).catch((error) => {
        console.log(error);
        this.$store.commit(
          'addNotification',
          new NotificationObject('error', error.response.data)
        );
      });
    },
    getNumberOfEmailsForBrevoList() {
      if (this.hideBrevoButton) return;
      this.loading = true;
      HTTP.get('/brevo/numberOfContactsInProject/' + this.getProjectId())
        .then((response) => {
          this.brevoListContactCounts = response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading = false));
    },
    getProjectId() {
      if (this.project && this.project.projektId) {
        return this.project.projektId;
      } else if (this.projectId) {
        return this.projectId;
      }
      return undefined;
    },
    tkiDownload() {
      axios({
        url:
          'http://localhost:3200/api/geo/tki-download?projektId=' +
          this.projectIntern.projektId, // Download File URL Goes Here
        method: 'GET',
        responseType: 'blob',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': ' GET, PUT, POST, DELETE, OPTIONS',
          'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
          'Access-Control-Allow-Credentials': 'false',
          Authorization: 'Basic cGFzdGFkbWluOjhOcDkyalYxcGU='
        }
      }).then((resp) => {
        // var FILE = window.URL.createObjectURL(new Blob([res.data]));
        // var docUrl = document.createElement('x');
        // docUrl.href = FILE;
        // docUrl.setAttribute('download', 'file.csv');
        // document.body.appendChild(docUrl);
        // docUrl.click();
        const responseHeaders = resp.headers;
        console.log(responseHeaders);
        const mediaType = responseHeaders['content-type'];
        console.log(mediaType);
        const fileName =
          'tkiExport_Projekt_ID_' +
          this.projectIntern.projektId +
          '(' +
          this.projectIntern.city +
          '_' +
          this.projectIntern.district +
          ').csv';
        // const fileName = responseHeaders['content-disposition'].split('=')[1];
        console.log(fileName);
        console.log(resp.data);
        const blob = new Blob([resp.data], {
          type: mediaType
        });
        console.log(resp);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
        console.log('Link:', link, link.href);
      });
    },
    tkiExport() {
      this.loading = true;
      HTTP.get(
        '/past/geo/tki-export?projektId=' + this.projectIntern.projektId,
        {
          headers: this.headers
        }
      )
        .then((response) => {
          this.$store.commit(
            'addNotification',
            new NotificationObject('success', 'Adressen erfolgreich exportiert')
          );
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style scoped lang="scss">
.number-table tr td:last-child {
  text-align: right;
}
.statistic-display h3 {
  color: #222;
  small {
    font-size: 10px;
  }
}
</style>
