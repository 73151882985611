var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[(!_vm.editProject)?[((_vm.project && _vm.project.projektId) || _vm.projectIntern)?_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',[_vm._v("Projekt-ID")]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.projectIntern.projektId))])])]),_c('tr',[_c('td',[_vm._v("Name")]),_c('td',[_c('b',[_vm._v(_vm._s(_vm.projectIntern.name))])])]),_c('tr',[_c('td',[_vm._v("Ort")]),_c('td',[_vm._v(_vm._s(_vm.projectIntern.city))])]),_c('tr',[_c('td',[_vm._v("Ortsteil")]),_c('td',[_vm._v(_vm._s(_vm.projectIntern.district))])]),_c('tr',[_c('td',[_vm._v("Landkreis")]),_c('td',[_vm._v(_vm._s(_vm.projectIntern.county))])]),_c('tr',[_c('td',[_vm._v("Vertriebsphase")]),(_vm.projectIntern.distributionStatus)?_c('td',[_c('b',{style:({ color: _vm.projectIntern.distributionStatus.color })},[_vm._v(" "+_vm._s(_vm.projectIntern.distributionStatus.description)+" ")])]):_c('td',[_vm._v(" - ")])]),_c('tr',[_c('td',[_vm._v("Projektstatus")]),(_vm.projectIntern.projectStatus)?_c('td',[_c('b',{style:({ color: _vm.projectIntern.projectStatus.color })},[_vm._v(" "+_vm._s(_vm.projectIntern.projectStatus.description)+" ")])]):_c('td',[_vm._v(" - ")])]),_c('tr',[_c('td',[_vm._v("Bausteuerungstool")]),(_vm.projectIntern.constructionManagementTool)?_c('td',[_c('b',[_vm._v(" "+_vm._s(_vm.projectIntern.constructionManagementTool)+" ")])]):_c('td',[_vm._v(" - ")])]),_c('tr',[_c('td',[_vm._v("Tarifmodell")]),(_vm.projectIntern.tariffModel)?_c('td',[_vm._v(" "+_vm._s(_vm.projectIntern.tariffModel.name)+" ")]):_c('td',[_vm._v(" - ")])]),_c('tr',[_c('td',[_vm._v("2Gbit")]),(
                  _vm.projectIntern.technologyStatus &&
                    _vm.projectIntern.technologyStatus[0] &&
                    _vm.projectIntern.technologyStatus[0].technology &&
                    _vm.projectIntern.technologyStatus[0].status &&
                    _vm.projectIntern.technologyStatus[0].status.description
                )?_c('td',[(
                    _vm.projectIntern.technologyStatus[0].status.name ===
                      '2g-ready' ||
                      _vm.projectIntern.technologyStatus[0].status.name === 'active'
                  )?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]):(
                    _vm.projectIntern.technologyStatus[0].status.name ===
                      'not-available'
                  )?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")]):_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-help-circle ")]),_vm._v(" "+_vm._s(_vm.projectIntern.technologyStatus[0].status.description)+" ")],1):_c('td',[_vm._v(" - ")])]),_c('tr',[_c('td',[_vm._v("Versandart Hardware")]),(_vm.projectIntern.shippingMethod)?_c('td',[_vm._v(" "+_vm._s(_vm.projectIntern.shippingMethod.name)+" ("+_vm._s(_vm.projectIntern.shippingMethod.description)+") ")]):_c('td',[_vm._v(" - ")])]),_c('tr',[_c('td',[_vm._v("FTTC Sofortversorgung möglich")]),_c('td',[(_vm.projectIntern.fttcInstantSupply)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-circle ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)])])]),(_vm.statisticData)?_c('v-alert',{staticClass:"kpi-alert statistic-display",attrs:{"color":"tertiary","border":"left","text":"","prominent":""}},[_c('h3',[_vm._v("Statistikdaten")]),_c('v-simple-table',{staticClass:"number-table px-4"},[_c('tbody',[_c('tr',[_c('td',[_c('b',[_vm._v("Anzahl FTTH-Aufträge")])]),_c('td',[_vm._v(_vm._s(_vm.statisticData.contractCount))])]),_c('tr',[_c('td',[_c('b',[_vm._v("BP+ (Plan)")]),_c('small',[_vm._v(" (Adressen)")])]),_c('td',[_vm._v(_vm._s(_vm.statisticData.buildingsPassedPlusPlan))])]),_c('tr',[_c('td',[_c('b',[_vm._v("BP+ Quote")])]),_c('td',[_vm._v(" "+_vm._s(_vm.formatPercent(_vm.statisticData.buildingsPassedQuota))+" ")])]),_c('tr',[_c('td',[_c('b',[_vm._v("HP (Plan)")]),_c('small',[_vm._v(" (Wohneinheiten)")])]),_c('td',[_vm._v(_vm._s(_vm.statisticData.homesPassedPlan))])]),_c('tr',[_c('td',[_c('b',[_vm._v("HP Quote")])]),_c('td',[_vm._v(_vm._s(_vm.formatPercent(_vm.statisticData.homesPassedQuota)))])])])])],1):_vm._e()],1),_c('v-col',{attrs:{"md":"6"}},[_c('DetailMap',{attrs:{"project-id":_vm.projectIntern.projektId}}),(_vm.kpis && _vm.kpis.length)?_c('KpiDisplay',{attrs:{"kpi":_vm.kpis,"mode":"alert"}}):_vm._e(),(
            this.$auth
              .user()
              .userRoles.some(
                function (element) { return ['camunda-admin', 'LgSpotMarketingAnalyse'].indexOf(
                    element
                  ) >= 0; }
              )
          )?_c('div',[(!_vm.hideCleverReachButton)?_c('v-btn',{staticClass:"my-6 mx-auto",attrs:{"color":"primary"},on:{"click":_vm.generateCleverReachList}},[_vm._v(" CleverReach Verteilerliste Generieren "+_vm._s(_vm.numberOfAddressesInCleverReachList !== undefined ? '(' + _vm.numberOfAddressesInCleverReachList + ' Adressen)' : '')+" ")]):_vm._e(),(!_vm.hideBrevoButton)?_c('v-btn',{staticClass:"mb-6 mx-auto",attrs:{"color":"primary"},on:{"click":_vm.generateBrevoList}},[_vm._v(" Brevo Verteilerlisten Generieren "+_vm._s(_vm.brevoListContactCounts !== undefined ? '(' + _vm.brevoListContactCounts.receiverCount + ' und ' + _vm.brevoListContactCounts.advertisingReceiverCount + ' Adressen)' : '')+" ")]):_vm._e()],1):_vm._e(),(
            this.$auth
              .user()
              .userRoles.some(
                function (element) { return ['camunda-admin', 'LgSpotGnc'].indexOf(element) >= 0; }
              )
          )?_c('div',[_c('v-btn',{staticClass:"my-2 mx-auto",attrs:{"color":"warning"},on:{"click":_vm.tkiExport}},[_c('v-icon',[_vm._v("mdi-bulldozer")]),_vm._v(" Adressen zu TKI Exportieren ")],1)],1):_vm._e()],1)],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }